<template>
  <div class="pdf-viewer">
   <Header/>
    <!-- <pdf src="/static/ppp.pdf"></pdf> -->
    <iframe :src="pdfSrc" width="100%" align="middle"></iframe>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  data() {
    return {
      pdfSrc: "",
    };
  },
  components: { Header,},
  computed: {},
  mounted() {
    // this.pdfSrc = `http://xltotal.xlteacher.cn${this.$route.query.pu}`;
    this.pdfSrc = this.$route.query.pu;
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.pdf-viewer {
  min-height: calc(100vh - 80px);
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 20px;
  iframe {
    border: 0;
    min-height: calc(100vh - 90px);
  }
}
</style>
